import React from 'react'
import { graphql } from 'gatsby'
import Type, {TypeQueryData} from '../../templates/type'

interface Props {
    data: TypeQueryData
}
export default ({data}: Props) => {

    const HoofdTekst = () => {
        return (
            <>
            </>
        )
    }

    return (
        <Type
            data={data}
            title={'Type D'}
            soortWoningen={'1 kadewoning - special'}
            aantalWoningen={'Vierlaags door extra opbouw'}
            hoofdTekst={<HoofdTekst/>}
            pageDescription={'Alle woningen van Kade 23 lopen in een kleine bocht mee met de gracht. Dit betekent dat ze taps toelopen naar het eind. De voorkant van de woning is dan ook breder dan de achterkant en geeft een speels effect.'}
            kenmerken={[
                'Woonoppervlak van 137,5 m2',
                'Kopwoning met entree aan de zijkant',
                'Extra verdieping',
                'Mogelijkheid voor een dakterras',
                'Perceel 85 m2',
                'Overdekte parkeerplek in achtergelegen parkeergarage'
            ]}
        />
    );
}

export const pageQuery = graphql`
    query {
        bouwtype(diversen: {objectcode: {eq: "O100099"}}) {
            ...Bouwtype
        }
        other: allBouwtype(filter: {diversen: {objectcode: {ne: "O100099"}}}) {
            nodes {
                algemeen {
                    omschrijving
                }
                fields {
                    slug
                }
            }
        }
    }
`
